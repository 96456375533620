<template>
  <div>
    <!-- 种类 -->
    <div class="work-nav">
      <span class="type">Type</span>
      <div class="nav-list">
        <span
          :class="['nav-item', item.active ? 'active' : '']"
          v-for="(item, i) in cateList"
          :key="i"
          @click="getList(item.id, i)"
          >{{ item.ename }}</span
        >
      </div>
    </div>
    <!-- 种类列表 -->
    <div class="type-container">
      <div
        class="type-item"
        @mouseenter="item.showCover = true"
        @mouseleave="item.showCover = false"
        @click="goDetail(item.id)"
        v-for="(item, i) in workList"
        :key="i"
      >
        <img :src="baseURL + item.image" alt="" class="type-img" />
        <span class="type-title">{{ item.title }}</span>
        <div class="cover" v-if="item.showCover">
          <div class="link-click">
            <img src="@/assets/news/link.png" alt="" class="link-img" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkCate, getWorks } from "@/api";
export default {
  data() {
    return {
      baseURL: "http://adminwxdesy.wxjoi.com/",
      activeCate: 0,
      cateList: [],
      workList: [],
    };
  },
  created() {
    let id = window.localStorage.getItem("selectedId");
    if (id) {
      this.activeCate = id;
    } else {
      this.activeCate = 0;
    }
  },
  mounted() {
    this.getCate();
  },
  methods: {
    // 获取案例分类
    async getCate() {
      try {
        this.cateList = [{ id: 0, name: "全部", ename: "全部" }];
        const result = await getWorkCate();
        this.cateList = [...this.cateList, ...result.list];
        this.cateList = this.cateList.map((item) => {
          this.$set(item, "active", false);
          return item;
        });
        this.cateList[0].active = true;
        // 拿到分类后获取全部列表
        this.getList(this.cateList[this.activeCate].id, this.activeCate);
      } catch (error) {
        alert(error);
      }
      window.localStorage.removeItem("selectedId");
    },
    // 获取案例列表
    async getList(id, i) {
      this.workList = [];
      this.activeCate = i;
      // console.log("index", this.cateList[i].active);
      this.cateList.forEach((item) => (item.active = false));
      this.cateList[i].active = true;
      try {
        const result = await getWorks({ cate_id: id });
        this.workList = result.list.map((item) => {
          this.$set(item, "showCover", false);
          return item;
        });
      } catch (error) {
        alert(error);
      }
    },
    goDetail(id) {
      this.$router.push({
        path: "/case",
        query: {
          id: id,
          active: this.activeCate,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.work-nav {
  background: #ffffff;
  width: 100%;
  box-sizing: border-box;
  padding: 54px 44px 18px 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .type {
    font-size: 16px;
    font-family: Arial;
    font-weight: 400;
    color: #080404;
  }
  .nav-list {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .nav-item {
      font-size: 7px;
      font-family: PingFang SC;
      color: #080404;
      margin-right: 15px;
      cursor: pointer;
      &.active {
        font-weight: bold;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }
}
.type-container {
  background: #f8f8f8;
  padding: 23px 43px 26px 43px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  .type-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-right: 8px;
    margin-bottom: 19px;
    width: 216px;
    position: relative;
    cursor: pointer;
    &:nth-child(3n) {
      margin-right: 0px;
    }
    .type-img {
      width: 216px;
      height: 141px;
    }
    .type-title {
      font-size: 7px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      width: 216px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding-top: 8px;
    }
    .cover {
      width: 216px;
      height: 141px;
      background: rgba(0, 0, 0, 0.8);
      position: absolute;
      top: 0px;
      left: 0px;
      .link-click {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 26px;
        height: 26px;
        border: 1px solid #ffffff;
        border-radius: 50%;
        .link-img {
          width: 10px;
          height: 9px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>
